// src/components/About.js
import React from "react";
import "../style/About.css"; // Ensure to create and import the About CSS

const About = () => {
  return (
    <section id="about" className="about-container">
      <h1>About Me</h1>
      <div className="about-content">
        <div className="about-left">
          <h2 style={{ textAlign: "center" }}>Hi, I'm Antas! 👋</h2>
          <p>💻 Software Developer</p>
          <p>
            My passion lies in solving challenging problems, designing efficient
            algorithms, and crafting robust software solutions. I excel in both
            independent projects and collaborative team environments, always
            striving to exceed expectations.
          </p>
          <p>
            In my spare time, I enjoy exploring new technologies, working on
            side projects, and engaging in outdoor activities.
          </p>
          <p>
            I am passionate about continuous learning and leveraging my skills
            to create meaningful impact.
          </p>
          <p>
            If you'd like to connect or discuss tech, feel free to reach out via
            Twitter or email.
          </p>
          <h2>⚡ Technical Skills ⚡</h2>
          <table className="skills-table">
            <tbody>
              <tr>
                <td>
                  <strong>Programming Languages:</strong>
                </td>
                <td>Java, Python, JavaScript, HTML, CSS, C, C++, Kotlin</td>
              </tr>
              <tr>
                <td>
                  <strong>Concepts and Frameworks:</strong>
                </td>
                <td>
                  Rest API, GraphQL, Spring Boot, Hibernate, JPA, React, React
                  Native, Database management, SQL Dialects (MySQL, MSSQL,
                  PostgreSQL), NoSQL DB, Image Processing, Artificial
                  Intelligence, Machine Learning, Node.js, Express.js, JUnit,
                  Selenium
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Developer Tools:</strong>
                </td>
                <td>
                  Android Studio, Eclipse, IntelliJ IDEA, Jupyter Notebook,
                  Spyder, VS Code, Git, SVN, Jira, Confluence, SQL Workbenches
                  (MySQL, MSSQL, PostgreSQL)
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Others:</strong>
                </td>
                <td>
                  Strong Practical knowledge of Object Oriented Programming and
                  Problem Solving
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default About;
