import React, { useState } from "react";
import "../style/Projects.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const projectData = [
  {
    title: "Quitter.ai - GraphQL App",
    techStack:
      "Spring Boot, GraphQL, JWT, Liquibase, Maven, PostgreSQL, ChatGPT API",
    description: [
      "Designed and developed a GraphQL-based backend application for tracking and managing addictive behaviors, enabling efficient data operations such as creation, retrieval, and deletion of records.",
      "Implemented secure JWT authentication and integrated Spring Security for role-based access control, safeguarding sensitive user data.",
      "Optimized database operations using PostgreSQL, resulting in improved query performance and scalability.",
      "Next Phase of development is focused on integrating ChatGPT API on the back-end to help user cope and get customized Tips, Motivations and other insights to fight with their addictions.",
    ],
    link: "https://github.com/AntasJain/quitterAi",
  },
  {
    title: "SuperWars - Superhero Card Game",
    techStack: "React, Redux, Bootstrap, JavaScript",
    description: [
      "Designed and developed a dynamic card game leveraging React and Redux for state management, creating an engaging user experience through interactive gameplay.",
      "Implemented responsive UI components using React-Bootstrap, ensuring the application is user-friendly.",
      "Developed game mechanics allowing the player to select superhero statistics for head-to-head battles with the computer, utilizing custom hooks to manage game state and effects.",
      "Employed Redux for efficient state management, handling player data, game state, and character information seamlessly.",
      "Implemented asynchronous data fetching using createAsyncThunk to retrieve superhero data from an external API, ensuring timely updates to the game state.",
      "The project shall be built further to include real-time player vs player games as an audacious future update.",
    ],
    link: "https://github.com/AntasJain/SuperWars", // Update with the actual GitHub link
    webAppLink: "https://playsuperwars.netlify.app",
  },
  {
    title: "Number Guessing Game",
    techStack: "React Native, Javascript",
    description: [
      "Developed a Cross-platform multi-screen application with react native.",
      "Implemented Hooks and other features provided by react native",
    ],
    link: "https://github.com/AntasJain/NumberGuessing",
  },
  {
    title: "Diabeat - Diabetes Recipes App",
    techStack: "Spring Boot, MySQL, JWT, Swagger, Android, Kotlin",
    description: [
      "Architected and developed a comprehensive API using Spring Boot to provide recipes tailored for diabetics, which also includes nutrition values.",
      "Implemented JWT-based session management to authenticate and authorize users securely, ensuring role-based access control for enhanced security of endpoints.",
      "Engineered RESTful APIs for managing recipes, ingredients, and bookmarks, supporting CRUD operations and ensuring efficient data handling and integration.",
      "Integrated Swagger for API documentation.",
      "Utilized MySQL for data persistence, ensuring reliable storage and retrieval of user-specific dietary information and preferences.",
      "Extending the application by developing an Android interface, using Kotlin as well as Java, providing users with convenient access to recipes. With features such as Search by Ingredients and bookmarking the recipes, while maintaining the MVP architecture for separation of concerns.",
    ],
    link: "https://github.com/AntasJain/diabeat-api-backend",
  },
  {
    title: "usePopcorn - Movie Search and Rating App",
    techStack: "React, OMDB API, Local Storage, Custom Hooks, PropTypes",
    description: [
      "Developed custom React hooks for state management, local storage, and keyboard interactions, enhancing app functionality and user experience.",
      "Integrated the OMDB API to fetch and display movie data, incorporating error handling, loading states, and a reusable star rating component with dynamic and customizable features.",
      "Implemented local storage for persistent user preferences and search results, while utilizing PropTypes for robust component validation.",
    ],
    link: "https://github.com/AntasJain/usePopcorn",
    webAppLink: "http://usepopcorn-antasjain.netlify.app",
  },
  {
    title: "Split Bill Management App",
    techStack: "React, JavaScript, CSS",
    description: [
      "Developed a React-based application to manage and split expenses among friends, featuring functionalities for adding friends, tracking balances, and handling transactions.",
      "Implemented dynamic forms and state management using React hooks, with a responsive UI for seamless user interaction. Applied CSS for styling and layout to ensure an intuitive user experience.",
    ],
    link: "https://github.com/AntasJain/SplitBill",
    webAppLink: "http://splitbillapp-antasjain.netlify.app",
  },

  {
    title: "Student Engagement - Emotion Prediction Model",
    techStack: "Artificial Intelligence, Python, Neural Networks",
    description: [
      "Led design and training of PyTorch-based CNN for facial image analysis in project. Prioritized ethical considerations, experimented with architectural variations, and assessed performance metrics.",
      "Mitigated biases in the AI model through thorough analysis, addressing potential issues related to age and gender. Applied dataset augmentation and retraining strategies to enhance fairness and inclusivity in the project.",
    ],
    link: "https://github.com/AntasJain/COMP-6721-Project",
  },
  {
    title: "Retinal Vessel Extraction using MF-FDOG",
    techStack: "Image Processing, Python",
    description: [
      "Re-implemented the (Matched Filter and First Derivative of Gaussian (MF-FDOG) algorithm for retinal vessel extraction based on Zhang et al.’s paper. Achieved a high accuracy of 96.29% on a DRIVE Dataset subset through meticulous algorithm design and testing.",
      "Conducted a thorough review of retinal vessel extraction techniques, comparing the MF-FDOG approach with a modified multiscale version. Fine-tuned hyperparameters, tested configurations, and validated performance using accuracy metrics and ROC analysis.",
    ],
    link: "https://github.com/AntasJain/Retinal-Vessel-Extraction-Using-MF-FDOG",
  },
  {
    title: "Open Source Contribution: OpenTracks (CourseWork)",
    techStack: "Android, Java, Sensors, Git",
    description: [
      "Contributed towards increasing the efficiency of the Sensors component of Opentrack Fitness tracking application.",
      "Led a team of 5 toward working in an agile method, together fixed 100+ bugs and improved code quality by more than 20%",
    ],
    link: "https://github.com/AntasJain/OpenTracks-Concordia",
  },
  {
    title: "Distributed Movie Ticket Booking System",
    techStack: "Java, Web Services, RMI",
    description: [
      "Crafted a sophisticated Distributed Movie Ticket Booking System featuring multiple servers.",
      "Executed the project in two distinct approaches: utilizing Web Services and Remote Method Invocation in Java.",
    ],
    link: "https://github.com/AntasJain/Distributed-Movie-Ticket-Booking-System__RMI",
  },
];

const Projects = () => {
  const [expandedProjects, setExpandedProjects] = useState(
    new Array(projectData.length).fill(false)
  );

  const toggleProject = (index) => {
    setExpandedProjects((prevExpanded) => {
      const updatedExpanded = [...prevExpanded];
      updatedExpanded[index] = !prevExpanded[index];
      return updatedExpanded;
    });
  };

  return (
    <section id="projects" className="projects-section">
      <h2 className="section-title">Projects</h2>
      <ul className="projects-list">
        {projectData.map((project, index) => (
          <li className="project-item" key={index}>
            <div
              className="project-header"
              onClick={() => toggleProject(index)}
            >
              <h3 className="project-title">{project.title}</h3>
              <p className="project-techStack">
                <strong>Tech Stack:</strong> {project.techStack}
              </p>
              <span className="expand-icon">
                <FontAwesomeIcon
                  icon={expandedProjects[index] ? faChevronDown : faChevronUp}
                />
              </span>
            </div>
            {expandedProjects[index] && (
              <>
                <ul className="project-description">
                  {project.description.map((bullet, i) => (
                    <li key={i}>{bullet}</li>
                  ))}
                </ul>
                <a
                  href={project.link}
                  className="project-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View on GitHub
                </a>
                {project.webAppLink && ( // Check if webAppLink exists
                  <a
                    href={project.webAppLink}
                    className="project-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Visit Web App
                  </a>
                )}
              </>
            )}
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Projects;
